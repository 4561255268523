import { ArrowBack } from '@styled-icons/material';
import { createStyledIcon } from '@trmediaab/zebra-icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getRotation = direction => {
  switch (direction) {
    case 'up':
      return 'rotate(90deg)';
    case 'down':
      return 'rotate(-90deg)';
    case 'right':
      return 'rotate(180deg)';
    default:
      return;
  }
};

const Arrow = styled(createStyledIcon(ArrowBack))`
  transform: ${props => getRotation(props.direction)};
`;

Arrow.propTypes = {
  direction: PropTypes.oneOf(['up', 'right', 'down', 'left']),
};

Arrow.defaultProps = {
  direction: 'left',
};

export default Arrow;
