import {
  BorderBox,
  Box,
  Button,
  FlexBox,
  Heading,
  Position,
  Rhythm,
  Text,
} from '@trmediaab/zebra';
import PropTypes from 'prop-types';

import Close from '~/icons/Close';

const Confirm = ({ title, text, confirmLabel, onConfirm, onCancel }) => (
  <Position.fixed
    zIndex="modal"
    top="0"
    left="0"
    width="100vw"
    height="100vh"
    bg="overlay"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <BorderBox borderRadius="2" bg="white" width="320px" p="2">
      <FlexBox justifyContent="flex-end">
        <Button onClick={onCancel}>
          <Close color="greys.3" size="24px" />
        </Button>
      </FlexBox>
      <Rhythm between="4" px="3,5" py="4">
        <Heading.h4 textAlign="center">{title}</Heading.h4>
        <Text.p textAlign="center">{text}</Text.p>
        <Box pt="2">
          <Button bc="primary" width="100%" onClick={onConfirm}>
            {confirmLabel}
          </Button>
        </Box>
        <Box>
          <Button bc="secondary" width="100%" onClick={onCancel}>
            Avbryt
          </Button>
        </Box>
      </Rhythm>
    </BorderBox>
  </Position.fixed>
);

Confirm.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

Confirm.defaultProps = {
  confirmLabel: 'OK',
};

export default Confirm;
